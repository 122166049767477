import { memo as Memo, useMemo } from 'react'

//* HOC's
import { withDataContext, withLanguageContext, withUIContext } from 'context'

//* Components
import { Page, Cursor, GlobalInfoPopup } from 'components/common'
import {
	HeroSection,
	AboutSection,
	SupportSection,
	StepInToParajanovSection,
	KaleidoscopeSection,
	FilmSection,
	EventsSection,
	NewsSection,
} from 'components/pages'

const Home = Memo(({ pages, global, pageParams, selectedLang, winWidth, ...props }) => {
	//! Page Data
	const pageData = useMemo(() => pages?.[pageParams.name]?.[selectedLang], [pages, selectedLang, pageParams.name])

	return (
		<Page className='home' meta={pageData?.content.meta}>
			{pageData?.content && (
				<>
					{winWidth >= 1024 ? <GlobalInfoPopup /> : ''}
					<HeroSection heroText={pageData.content.hero_section_text} isSafari={props.isSafari} />
					<AboutSection aboutText={pageData.content.about_section_text} aboutTitle={pageData.content.about_section_title} />
					<SupportSection donateText={pageData.content.donate_section_text} isSafari={props.isSafari} />
					<Cursor text='VisitGallery' typeCursor={'home'}>
						<StepInToParajanovSection />
						<FilmSection />
					</Cursor>
					<NewsSection data={pageData.news} />
					<EventsSection data={pageData.events} />
					<KaleidoscopeSection
						author={pageData.content.kaleidoscope_author}
						description={pageData.content.kaleidoscope_text}
						isSafari={props.isSafari}
					/>
				</>
			)}
		</Page>
	)
})

export default withLanguageContext(withUIContext(withDataContext(Home, ['pages', 'global']), ['winWidth']), ['selectedLang'])
